<script>
// @ts-check
import messageApi from '@/apis/messageApi';
import { computed, reactive, watchEffect } from 'vue';
import Pagination from '@/components/UIs/Pagination.vue';
import { formatFbDate, displayMaxLengthText, fDefined, f } from '@/services/utils';
import { globalState } from '@/stores/global';
import { getFieldValue, getMessageReadLogDoc, messageReadLogCol } from '@/apis/utils';
import { usePagination, usePublicizedMessagesByUser } from '@/hooks/query';
import router from '@/router';
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';
import _ from 'lodash';

const PER_PAGE = 10;

const SENDER_TYPE_DISPLAY = {
	0: '≪あすたーとぴーすからお知らせ≫',
	1: '≪応援メッセージ≫'
};

export default {
	components: { Pagination },
	setup() {
		const state = reactive({
			markAsReadedFlag: true,
			markAsReadedSubmitting: false
		});
		const messages = usePublicizedMessagesByUser(globalState.user.id);
		const { visibleList, listLength, maxPage } = usePagination(
			computed(() => {
				let listMessage = messages.data.value || [];
				listMessage = _.orderBy(listMessage, m => m.publish_date, 'desc');
				return listMessage;
			}),
			{ PER_PAGE }
		);
		watchEffect(() => {
			state.markAsReadedFlag = (messages.data.value || []).every(m => m.messageReadLog);
		});
		return {
			state,
			messages,
			formatFbDate,
			dayjs,
			SENDER_TYPE_DISPLAY,
			displayMaxLengthText,
			visibleList,
			listLength,
			maxPage,
			PER_PAGE,
			/** @param {t.Message}  message */
			gotoMessageDetailAction(message) {
				messageApi.messageReadLogCol
					.doc(
						getMessageReadLogDoc({
							user_id: f(globalState.user.id),
							message_id: message.docId
						})
					)
					.set({
						message_id: message.docId,
						user_id: fDefined(globalState.user.id),
						delete_flag: 0,
						// @ts-ignore
						created_at: getFieldValue().serverTimestamp(),
						updated_at: null
					})
					.catch(err => {
						console.log(err);
					});
				router.push({ name: 'message_detail', params: { messageId: message.docId } });
			},
			markAsReaded: async () => {
				if (state.markAsReadedSubmitting) return;
				state.markAsReadedSubmitting = true;
				try {
					const promises = [];
					// eslint-disable-next-line no-restricted-syntax
					for (const m of messages.data.value || []) {
						// eslint-disable-next-line no-continue
						if (m.messageReadLog) continue;

						const notExistLogId = getMessageReadLogDoc({ user_id: f(globalState.user.id), message_id: m.docId });
						promises.push(
							messageReadLogCol.doc(notExistLogId).set({
								message_id: m.docId,
								user_id: fDefined(globalState.user.id),
								delete_flag: 0,
								// @ts-ignore
								created_at: getFieldValue().serverTimestamp(),
								updated_at: null
							})
						);
					}
					await Promise.all(promises);
					messages.refetch.value();
				} catch (error) {
					console.log('error');
				} finally {
					state.markAsReadedSubmitting = false;
				}
			}
		};
	}
};
</script>
<template>
	<div class="flex-1">
		<div class="container" style="min-height: calc(100vh - 154px)">
			<label class="block lg:pt-20.8 lg:pb-18.75 lg:text-4xl text-xl font-medium  border-gray-300 lg:pl-12.5 p-4">メッセージ一覧</label>
			<div class="mb-2 ml-2 flex justify-end">
				<label v-if="!state.markAsReadedFlag" class="mr-4">
					<input type="checkbox" class="form-checkbox rounded " @click="markAsReaded" />
					全て開封済みにする
				</label>
			</div>
			<!-- <div class="pr-3 text-right">Unread Message: {{ 0 }}</div> -->
			<div v-for="(message, index) in visibleList" :key="message.docId">
				<div
					:class="[index === 0 ? 'border-t-2' : '']"
					class="flex flex-row items-center justify-between lg:pl-12.5 lg:pr-14.8 border-b-2 border-gray-300 cursor-pointer p-4"
					@click="gotoMessageDetailAction(message)"
				>
					<div class="text-sm font-medium leading-6 lg:py-8.5 w-full lg:text-base">
						<div class="flex flex-row justify-between font-bold md:mb-2">
							<span class="break-words word-break whitespace-pre-line">{{ SENDER_TYPE_DISPLAY[message.sender_type] }}</span>
							<span class="ml-10">
								{{ dayjs(message.publish_date).format('YYYY-MM-DD') }}
							</span>
						</div>
						<p class="mb-3 break-words word-break whitespace-pre-line">{{ message.message_title }}</p>
						<div class="flex flex-row items-start justify-between">
							<p class="break-words word-break whitespace-pre-line">{{ displayMaxLengthText(message.message, 100) }}</p>
							<img v-if="!message.messageReadLog" src="@/assets/images/new_notification.png" alt="New Notification" class="w-12 h-5 ml-4" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!messages.isFetching.value && !listLength" class="m-3 text-center">
			データがありません
		</div>
		<pagination v-if="maxPage > 1" goto-route-name="message_list" :per-page="PER_PAGE" :total-record="messages.data.value?.length || 0" class="mb-8" />
	</div>
</template>
<style>
.word-break {
	word-break: break-word;
}
</style>
